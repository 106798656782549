import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...props} components={components} mdxType="MDXLayout">
    <h1>{`cssstats`}</h1>
    <p>{`Parses stylesheets and returns an object with statistics.
This is the core module used in `}
      <a {...{
        "href": "http://cssstats.com",
        "parentName": "p"
      }}>{`cssstats.com`}</a></p>
    <h2>{`Installation`}</h2>
    <pre><code {...{
        "className": "language-sh",
        "parentName": "pre"
      }}>{`npm i --save cssstats
`}</code></pre>
    <h2>{`Usage`}</h2>
    <h3>{`Node`}</h3>
    <pre><code {...{
        "className": "language-js",
        "parentName": "pre"
      }}>{`var fs = require('fs')
var cssstats = require('cssstats')

var css = fs.readFileSync('./styles.css', 'utf8')
var stats = cssstats(css)
`}</code></pre>
    <h3>{`PostCSS Plugin`}</h3>
    <p>{`CSS Stats can be used as a `}
      <a {...{
        "href": "https://github.com/postcss/postcss",
        "parentName": "p"
      }}>{`PostCSS`}</a>
      {` plugin.
The stats will be added to PostCSS's messages array.`}</p>
    <pre><code {...{
        "className": "language-js",
        "parentName": "pre"
      }}>{`var fs = require('fs')
var postcss = require('postcss')
var cssstats = require('cssstats')

var css = fs.readFileSync('./styles.css', 'utf8')
postcss()
  .use(cssstats())
  .process(css)
  .then(function (result) {
    result.messages.forEach(function (message) {
      console.log(message)
    })
  })
`}</code></pre>
    <h4>{`Options`}</h4>
    <p>{`Options may be passed as a second argument.`}</p>
    <pre><code {...{
        "className": "language-js",
        "parentName": "pre"
      }}>{`var stats = cssstats(css, { mediaQueries: false })
`}</code></pre>
    <ul>

      <li {...{
        "parentName": "ul"
      }}><inlineCode {...{
          "parentName": "li"
        }}>{`safe`}</inlineCode>
        {` (boolean, default: `}
        <inlineCode {...{
          "parentName": "li"
        }}>{`true`}</inlineCode>
        {`) - enables `}
        <a {...{
          "href": "https://github.com/postcss/postcss#safe-mode",
          "parentName": "li"
        }}>{`PostCSS safe mode`}</a>
        {` for parsing CSS with syntax errors`}</li>


      <li {...{
        "parentName": "ul"
      }}><inlineCode {...{
          "parentName": "li"
        }}>{`mediaQueries`}</inlineCode>
        {` (boolean, default `}
        <inlineCode {...{
          "parentName": "li"
        }}>{`true`}</inlineCode>
        {`) - determines whether or not to generate stats for each media query block`}</li>


      <li {...{
        "parentName": "ul"
      }}><inlineCode {...{
          "parentName": "li"
        }}>{`importantDeclarations`}</inlineCode>
        {` (boolean, default `}
        <inlineCode {...{
          "parentName": "li"
        }}>{`false`}</inlineCode>
        {`) - include an array of declarations with `}
        <inlineCode {...{
          "parentName": "li"
        }}>{`!important`}</inlineCode></li>

    </ul>
    <p>{`The following options add the results of helper methods to the returned object. This is helpful when using `}
      <inlineCode {...{
        "parentName": "p"
      }}>{`JSON.stringify()`}</inlineCode>
      {`.`}</p>
    <ul>

      <li {...{
        "parentName": "ul"
      }}><inlineCode {...{
          "parentName": "li"
        }}>{`specificityGraph`}</inlineCode>
        {` (boolean, default `}
        <inlineCode {...{
          "parentName": "li"
        }}>{`false`}</inlineCode>
        {`)`}</li>


      <li {...{
        "parentName": "ul"
      }}><inlineCode {...{
          "parentName": "li"
        }}>{`sortedSpecificityGraph`}</inlineCode>
        {` (boolean, default `}
        <inlineCode {...{
          "parentName": "li"
        }}>{`false`}</inlineCode>
        {`)`}</li>


      <li {...{
        "parentName": "ul"
      }}><inlineCode {...{
          "parentName": "li"
        }}>{`repeatedSelectors`}</inlineCode>
        {` (boolean, default `}
        <inlineCode {...{
          "parentName": "li"
        }}>{`false`}</inlineCode>
        {`)`}</li>


      <li {...{
        "parentName": "ul"
      }}><inlineCode {...{
          "parentName": "li"
        }}>{`propertyResets`}</inlineCode>
        {` (boolean, default `}
        <inlineCode {...{
          "parentName": "li"
        }}>{`false`}</inlineCode>
        {`)`}</li>


      <li {...{
        "parentName": "ul"
      }}><inlineCode {...{
          "parentName": "li"
        }}>{`vendorPrefixedProperties`}</inlineCode>
        {` (boolean, default `}
        <inlineCode {...{
          "parentName": "li"
        }}>{`false`}</inlineCode>
        {`)`}</li>

    </ul>
    <h3>{`Returned Object`}</h3>
    <pre><code {...{
        "className": "language-js",
        "parentName": "pre"
      }}>{`// Example
{
  size: n,
  gzipSize: n,
  rules: {
    total: n,
    size: {
      graph: [n],
      max: n,
      average: n
    }
  },
  selectors: {
    total: n,
    id: n,
    class: n,
    type: n,
    pseudoClass: n,
    psuedoElement: n,
    values: [str],
    specificity: {
      max: n
      average: n
    },
    getSpecificityGraph(),
    getSpecificityValues(),
    getRepeatedValues(),
    getSortedSpecificity()
  },
  declarations: {
    total: n,
    unique: n,
    uniqueToTotalRatio: n,
    important: [obj],
    properties:
      prop: [str]
    },
    getPropertyResets(),
    getUniquePropertyCount(),
    getPropertyValueCount(),
    getVendorPrefixed(),
    getAllFontSizes(),
    getAllFontFamilies(),
  },
  mediaQueries: {
    total: n,
    unique: n,
    values: [str],
    contents: [
      {
        value: str,
        rules: {
          total: n,
          size: {
            graph: [n],
            max: n,
            average: n
          }
        },
        selectors: {
          total: n,
          id: n,
          class: n,
          type: n,
          pseudoClass: n,
          pseudoElement: n,
          values: [str],
          specificity: {
            max: n,
            average: n
          }
        },
        declarations: {
          total: n,
          unique: n,
          important: [obj],
          vendorPrefix: n,
          properties: {
            prop: [str]
          }
        }
      }
    ]
  }
}
`}</code></pre>
    <h4><inlineCode {...{
        "parentName": "h4"
      }}>{`size`}</inlineCode>
      {` number`}</h4>
    <p>{`The size of the file in bytes`}</p>
    <h4><inlineCode {...{
        "parentName": "h4"
      }}>{`gzipSize`}</inlineCode>
      {` number`}</h4>
    <p>{`The size of the stylesheet gzipped in bytes`}</p>
    <h4><inlineCode {...{
        "parentName": "h4"
      }}>{`rules`}</inlineCode>
      {` object`}</h4>
    <ul>

      <li {...{
        "parentName": "ul"
      }}><inlineCode {...{
          "parentName": "li"
        }}>{`total`}</inlineCode>
        {` number - total number of rules`}</li>


      <li {...{
        "parentName": "ul"
      }}><inlineCode {...{
          "parentName": "li"
        }}>{`size`}</inlineCode>
        {` object`}
        <ul {...{
          "parentName": "li"
        }}>

          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`size.graph`}</inlineCode>
            {` array - ruleset sizes (number of declarations per rule) in source order`}</li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`size.max`}</inlineCode>
            {` number - maximum ruleset size`}</li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`size.average`}</inlineCode>
            {` number - average ruleset size`}</li>

        </ul></li>

    </ul>
    <h4><inlineCode {...{
        "parentName": "h4"
      }}>{`selectors`}</inlineCode>
      {` object`}</h4>
    <ul>

      <li {...{
        "parentName": "ul"
      }}><inlineCode {...{
          "parentName": "li"
        }}>{`total`}</inlineCode>
        {` number - total number of selectors`}</li>


      <li {...{
        "parentName": "ul"
      }}><inlineCode {...{
          "parentName": "li"
        }}>{`type`}</inlineCode>
        {` number - total number of type selectors`}</li>


      <li {...{
        "parentName": "ul"
      }}><inlineCode {...{
          "parentName": "li"
        }}>{`class`}</inlineCode>
        {` number - total number of class selectors`}</li>


      <li {...{
        "parentName": "ul"
      }}><inlineCode {...{
          "parentName": "li"
        }}>{`id`}</inlineCode>
        {` number - total number of id selectors`}</li>


      <li {...{
        "parentName": "ul"
      }}><inlineCode {...{
          "parentName": "li"
        }}>{`pseudoClass`}</inlineCode>
        {` number - total number of pseudo class selectors`}</li>


      <li {...{
        "parentName": "ul"
      }}><inlineCode {...{
          "parentName": "li"
        }}>{`pseudoElement`}</inlineCode>
        {` number - total number of pseudo element selectors`}</li>


      <li {...{
        "parentName": "ul"
      }}><inlineCode {...{
          "parentName": "li"
        }}>{`values`}</inlineCode>
        {` array - array of strings for all selectors`}</li>


      <li {...{
        "parentName": "ul"
      }}><inlineCode {...{
          "parentName": "li"
        }}>{`specificity`}</inlineCode>
        {` object`}
        <ul {...{
          "parentName": "li"
        }}>

          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`specificity.max`}</inlineCode>
            {` number - maximum specificity as a base 10 number`}</li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`specificity.average`}</inlineCode>
            {` number - average specificity as a base 10 number`}</li>

        </ul></li>


      <li {...{
        "parentName": "ul"
      }}><inlineCode {...{
          "parentName": "li"
        }}>{`getSpecificityGraph()`}</inlineCode>
        {` function - returns an array of numbers for each selector’s specificity as a base 10 number`}</li>


      <li {...{
        "parentName": "ul"
      }}><inlineCode {...{
          "parentName": "li"
        }}>{`getSpecificityValues()`}</inlineCode>
        {` function - returns an array of selectors with base 10 specificity score in order`}</li>


      <li {...{
        "parentName": "ul"
      }}><inlineCode {...{
          "parentName": "li"
        }}>{`getRepeatedValues()`}</inlineCode>
        {` function - returns an array of strings of repeated selectors`}</li>


      <li {...{
        "parentName": "ul"
      }}><inlineCode {...{
          "parentName": "li"
        }}>{`getSortedSpecificity()`}</inlineCode>
        {` function - returns an array of selectors with base 10 specificity score, sorted from highest to lowest`}</li>

    </ul>
    <h4><inlineCode {...{
        "parentName": "h4"
      }}>{`declarations`}</inlineCode>
      {` object`}</h4>
    <ul>

      <li {...{
        "parentName": "ul"
      }}><inlineCode {...{
          "parentName": "li"
        }}>{`total`}</inlineCode>
        {` number - total number of declarations`}</li>


      <li {...{
        "parentName": "ul"
      }}><inlineCode {...{
          "parentName": "li"
        }}>{`unique`}</inlineCode>
        {` number - total unique declarations`}</li>


      <li {...{
        "parentName": "ul"
      }}><inlineCode {...{
          "parentName": "li"
        }}>{`uniqueToTotalRatio`}</inlineCode>
        {` number - ratio of unique declarations to total declarations`}</li>


      <li {...{
        "parentName": "ul"
      }}><inlineCode {...{
          "parentName": "li"
        }}>{`properties`}</inlineCode>
        {` object - object with each unique property and an array of that property’s values`}</li>


      <li {...{
        "parentName": "ul"
      }}><inlineCode {...{
          "parentName": "li"
        }}>{`getPropertyResets()`}</inlineCode>
        {` function - returns an object with the number of times margin or padding is reset for each property`}</li>


      <li {...{
        "parentName": "ul"
      }}><inlineCode {...{
          "parentName": "li"
        }}>{`getUniquePropertyCount(property)`}</inlineCode>
        {` function - returns the number of unique values for the given property`}</li>


      <li {...{
        "parentName": "ul"
      }}><inlineCode {...{
          "parentName": "li"
        }}>{`getPropertyValueCount(property, value)`}</inlineCode>
        {` function - returns the number of times a declaration occurs for the given property and value`}</li>


      <li {...{
        "parentName": "ul"
      }}><inlineCode {...{
          "parentName": "li"
        }}>{`getVendorPrefixed()`}</inlineCode>
        {` function - returns an array of declarations with vendor prefixed properties`}</li>


      <li {...{
        "parentName": "ul"
      }}><inlineCode {...{
          "parentName": "li"
        }}>{`getAllFontSizes()`}</inlineCode>
        {` function - returns an array of font sizes from both `}
        <inlineCode {...{
          "parentName": "li"
        }}>{`font-size`}</inlineCode>
        {` and `}
        <inlineCode {...{
          "parentName": "li"
        }}>{`font`}</inlineCode>
        {` shorthand declarations`}</li>


      <li {...{
        "parentName": "ul"
      }}><inlineCode {...{
          "parentName": "li"
        }}>{`getAllFontFamilies()`}</inlineCode>
        {` function - returns an array of font families from both `}
        <inlineCode {...{
          "parentName": "li"
        }}>{`font-family`}</inlineCode>
        {` and `}
        <inlineCode {...{
          "parentName": "li"
        }}>{`font`}</inlineCode>
        {` shorthand declarations`}</li>


      <li {...{
        "parentName": "ul"
      }}><inlineCode {...{
          "parentName": "li"
        }}>{`important`}</inlineCode>
        {` array (optional) - `}
        <inlineCode {...{
          "parentName": "li"
        }}>{`!important`}</inlineCode>
        {` declaration objects with `}
        <inlineCode {...{
          "parentName": "li"
        }}>{`property`}</inlineCode>
        {` and `}
        <inlineCode {...{
          "parentName": "li"
        }}>{`value`}</inlineCode></li>

    </ul>
    <h4><inlineCode {...{
        "parentName": "h4"
      }}>{`mediaQueries`}</inlineCode>
      {` object`}</h4>
    <ul>

      <li {...{
        "parentName": "ul"
      }}><inlineCode {...{
          "parentName": "li"
        }}>{`total`}</inlineCode>
        {` number - total number of media queries`}</li>


      <li {...{
        "parentName": "ul"
      }}><inlineCode {...{
          "parentName": "li"
        }}>{`unique`}</inlineCode>
        {` number - total unique media queries`}</li>


      <li {...{
        "parentName": "ul"
      }}><inlineCode {...{
          "parentName": "li"
        }}>{`values`}</inlineCode>
        {` array - array of values for each media query`}</li>


      <li {...{
        "parentName": "ul"
      }}><inlineCode {...{
          "parentName": "li"
        }}>{`contents`}</inlineCode>
        {` array - array of media query blocks with full stats object for each`}</li>

    </ul>
    <p>{`See the `}
      <inlineCode {...{
        "parentName": "p"
      }}>{`/test/results`}</inlineCode>
      {` folder for example JSON results.`}</p>
    <h3>{`Usage examples`}</h3>
    <pre><code {...{
        "className": "language-js",
        "parentName": "pre"
      }}>{`var cssstats = require('cssstats')
var stats = cssstats(css)
`}</code></pre>
    <h4>{`Generate a `}
      <a {...{
        "href": "http://csswizardry.com/2014/10/the-specificity-graph/",
        "parentName": "h4"
      }}>{`specificity graph`}</a></h4>
    <pre><code {...{
        "className": "language-js",
        "parentName": "pre"
      }}>{`var specificityGraph = stats.selectors.getSpecificityGraph()
`}</code></pre>
    <h4>{`Sort selectors by highest specificity`}</h4>
    <pre><code {...{
        "className": "language-js",
        "parentName": "pre"
      }}>{`var sortedSelectors = stats.selectors.getSortedSpecificity()
`}</code></pre>
    <h4>{`Get total number of unique colors`}</h4>
    <pre><code {...{
        "className": "language-js",
        "parentName": "pre"
      }}>{`var uniqueColorsCount = stats.declarations.getUniquePropertyCount('color')
`}</code></pre>
    <h4><inlineCode {...{
        "parentName": "h4"
      }}>{`display: none`}</inlineCode>
      {` count`}</h4>
    <pre><code {...{
        "className": "language-js",
        "parentName": "pre"
      }}>{`var displayNoneCount = stats.declarations.getPropertyValueCount(
  'display',
  'none'
)
`}</code></pre>
    <h2>{`License`}</h2>
    <p>{`MIT`}</p>
    <h2>{`Contributing`}</h2>
    <ol>

      <li {...{
        "parentName": "ol"
      }}>{`Fork it`}</li>


      <li {...{
        "parentName": "ol"
      }}>{`Create your feature branch (`}
        <inlineCode {...{
          "parentName": "li"
        }}>{`git checkout -b my-new-feature`}</inlineCode>
        {`)`}</li>


      <li {...{
        "parentName": "ol"
      }}>{`Commit your changes (`}
        <inlineCode {...{
          "parentName": "li"
        }}>{`git commit -am 'Add some feature'`}</inlineCode>
        {`)`}</li>


      <li {...{
        "parentName": "ol"
      }}>{`Push to the branch (`}
        <inlineCode {...{
          "parentName": "li"
        }}>{`git push origin my-new-feature`}</inlineCode>
        {`)`}</li>


      <li {...{
        "parentName": "ol"
      }}>{`Create new Pull Request`}</li>

    </ol>
  </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      